@include header(
  140px,
  (
    $brLargeDesktop: 120px,
    $brDesktop: 120px,
    $brTablet: 92px,
    $brMobile: 55px
  )
);

.the-header {
  @include transition(height 0.3s, background 0.3s);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  text-align: right;
  background: $headerBg;

  &:hover {
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  #logo {
    @include align-center-v(2);
    display: block;

    img {
      @include transition(height 0.3s);
      height: 81px;
      margin: 0 auto;
    }

    .slogan {
      @include transition(font-size 0.3s);
      padding-top: 8px;
      text-align: center;
      font-size: rem(16);
      font-weight: 700;
      color: nth($color, 7);

      span {
        display: block;
        &.i1 {
          text-transform: uppercase;
        }
      }
    }

    img,
    .slogan {
      display: block;
    }
  }

  .wrap {
    position: relative;
    height: 100%;
  }

  .menu-toggle {
    @include align-center-v(2);
    right: 0;
    display: none;

    i {
      font-size: 42px;
      line-height: 1;
    }
  }

  .nav-header {
    @include transition(height 0.3s);
    position: absolute;
    right: 0;
    bottom: 0;
    height: calc(100% - 48px);
    text-align: right;

    .menu,
    .social-networks {
      display: inline-block;
      vertical-align: middle;
    }

    .menu {
      height: 100%;
      font-size: 0;

      > ul {
        @include clearfix;
        height: 100%;

        > li {
          position: relative;
          display: inline-block;
          height: 100%;
          font-size: $em-base;
          vertical-align: top;
          &.menu-item-has-children {
            &:hover {
              &:after {
                height: 8px;
              }
            }
          }

          &:after {
            @include align-center-h(2);
            @include transition(height 0.3s);
            @include filter(drop-shadow(0 1px 1px rgba(#000, 0.1)));
            top: 100%;
            display: block;
            content: "";
            width: 19px;
            height: 0;
            pointer-events: none;
            opacity: 0.95;
            background: url("#{$imgDir}/menu-marker.svg");
          }

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            > ul {
              @include transform(none);
              left: auto;
              right: 0;
            }
          }

          &.menu-item-has-children:hover,
          &.active {
          }

          &.current-menu-item,
          &.current-menu-ancestor,
          &.current_page_item,
          &.current_page_ancestor,
          &.current_page_parent {
            > a {
              &:after {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 2px;
                background: $blue;
              }
            }
          }

          &:hover {
            > a {
              &:after {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 2px;
                background: $blue;
              }
            }
          }

          &.hilite {
            margin-left: 1em;
            > a {
              color: $mainBg;
              background: nth($color, 1);
            }
          }

          > a {
            position: relative;
            top: 50%;
            display: block;
            margin-top: -1em;
            padding: 0.5em rem(15);
            font-family: $heading-font-family;
            font-weight: 500;
            line-height: 1;
            color: nth($color, 8);

            &:hover {
            }
          }

          a {
            &:hover {
              text-decoration: none;
            }
          }
          > ul {
            @include align-center-h(101);
            @include clearfix;
            top: 100%;
            padding: 0;
            min-width: 250px;
            text-align: left;
            line-height: 1;
            font-size: 15px;
            text-transform: none;

            li {
              display: block;
              background: $mainBg;
              box-shadow: 0 2px 4px rgba(#000, 0.25);
              &.current-menu-item {
                a {
                  font-weight: 700;
                  color: nth($color, 1);
                }
              }
              &:last-child {
                a {
                  border-bottom: 0;
                }
              }
              a {
                display: block;
                padding: 10px 20px;
                text-decoration: none;
                color: $blue;
                &:hover {
                  color: $mainBg;
                  background: $blue;
                }
              }
            }
          }
        }

        ul {
          display: none;
        }
      }

      a {
        color: nth($color, 4);
      }
    }

    .social-networks {
      margin-left: 30px;

      a {
        &:hover {
          i {
            color: nth($color, 1);
            opacity: 1;
          }
        }
      }

      i {
        color: nth($color, 8);
        font-size: 20px;
        opacity: 0.5;
      }
    }
  }

  nav.secondary {
    @include transition(height 0.3s);
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    height: rem(48);
    #search {
      margin-left: -15px;
      margin-right: 10px;
      &:focus {
        border-bottom-color: $blue;
      }
    }
    .menu {
      @include clearfix;
      li {
        display: block;
        float: left;
        height: 100%;
        a {
          @include transition(background 0.3s);
          display: block;
          height: 100%;
          padding: 0 1em;
          line-height: rem(48);

          &.search-menu {
            &:hover {
              background-color: $blue !important;
            }
          }

          &:hover {
            background: nth($color, 2) !important;
            span {
              color: #fff !important;
            }
          }
        }
        &:nth-child(2) {
          a {
            background-color: $pink !important;
          }
        }
        &:nth-child(3) {
          a {
            background-color: $blue !important;
          }
        }
      }
    }
  }

  .search-toggle {
    position: relative;
    margin-left: 20px;

    i {
      line-height: 1;
      color: nth($color, 2);
    }

    &:hover {
      i {
        color: nth($color, 3);
      }
    }
  }

  #growl {
    @include transition(opacity 0.3s);
    position: absolute;
    z-index: 100;
    right: 40px;
    top: 110%;
    display: none;

    .content {
      padding: 1em;
      background: #fff;
      border: 2px solid nth($color, 4);
      box-shadow: 0 0 15px rgba(#000, 0.5);
      .product {
        font-weight: 700;
      }
    }
  }

  @include media($brLargeDesktop) {
    #logo {
      .slogan {
        font-size: rem(12);
      }
    }
    .nav-header {
      .menu {
        > ul {
          > li {
            > a {
              font-size: rem(14);
            }
          }
        }
      }
    }
  }

  @include media($brDesktop) {
    .wrap {
    }

    #logo {
      @include transform(none);
      top: rem(15);

      .slogan {
        font-size: 12px;
      }
    }
    #growl {
      right: 20px;
    }

    .nav-header {
      .menu {
        > ul > li,
        > li {
          font-size: 12px;
          &.menu-item-has-children {
            > a:after {
            }
          }
          > a {
          }

          ul {
          }
        }
      }

      .social-networks {
        display: none;
      }
    }

    nav.secondary {
      .menu {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }

  @include media($brTablet) {
    > .wrap {
    }

    #logo {
      @include transform(translate(0, -50%));
      top: 50%;
    }

    #growl {
      left: 20px;
    }

    .menu-toggle {
      display: block;
    }

    .search-toggle {
      @include align-center-v(2);
      right: 50px;
      padding-right: 20px;
      border-right: 1px solid nth($color, 10);
      i {
        font-size: 24px;
      }
    }

    .nav-header {
      display: none;
    }

    nav.secondary {
      display: none;
    }
  }

  @include media($brMobile) {
    #logo {
      img {
        height: 30px;
      }
      .slogan {
        padding-top: 5px;
        font-size: 10px;
      }
    }
  }
}

body.scrolled {
  @include header(
    55px,
    (
      $brLargeDesktop: 55px,
      $brDesktop: 55px,
      $brTablet: 55px,
      $brMobile: 55px
    )
  );

  .the-header {
    border-bottom: 1px solid nth($color, 10);

    #logo {
      img {
        height: 40px;
      }

      .slogan {
        display: none;
      }
    }

    .nav-header {
      height: 100%;
    }
    nav.secondary {
      overflow: hidden;
      height: 0;
    }
  }

  @include media($brLargeDesktop) {
  }

  @include media($brDesktop) {
    .the-header {
      #logo {
        @include transform(translate(0, -50%));
        top: 50%;
        img {
          height: 30px;
        }
        .slogan {
          display: none;
        }
      }
    }
  }

  @include media($brTablet) {
    .the-header {
      #logo {
        img {
        }
      }
    }
  }
}
