#hero-image {
    position: relative;
    z-index: 1;
    height: 230px;

    .wrap {
        height: 100%;
    }

    .shade {
        &.bg {
            @include cover;
        }
        &.tint {
            @include linear-gradient(rgba(#fff, 0.2), rgba(#000, .25));
        }
    }

    header {
        @include align-center-v(2);
        z-index: 2;
        left: 0;
        right: 0;

        #breadcrumbs {
            position: absolute;
            z-index: 3;
            left: 0;
            bottom: 170px;
            opacity: .75;
            a {
                color: $mainBg;
            }
        }

        .title,
        .subtitle {
            color: $mainBg;
        }

        .title {
            position: relative;
            margin: 0;
            font-size: rem(46);
            font-weight:400;
        }

        .subtitle {
            font-size: 1.25em;
            font-weight: 200;
        }
    }

    @include media($brLargeDesktop) {
        header {
        }
    }

    @include media($brDesktop) {
        header {
        }
    }
    @include media($brTablet) {
        height: 200px;

        header {

            #breadcrumbs {
                bottom: 150px;
            }
            .title {
                font-size: 30px;
            }
        }
    }

    @include media($brMobile) {
    }
}

body.single-post {
    #hero-image {
        header {
            .title {
                font-size: 40px;
                text-transform: none;
            }
        }
    }
}