.post-content {
    ul.accordion {
        li {
            display: block;

            &.active {
                .header {
                    i {
                        @include transform(translateY(-50%) rotate(-180deg));
                    }
                }

                .content {
                }
            }

            .header {
                position: relative;
                z-index: 1;
                padding: .5em rem($em-base);
                border-bottom: 3px solid nth($color, 10);
                cursor: pointer;

                .title {
                    margin: 0;
                    padding: 0;
                    color: nth($color, 1);
                }

                i {
                    @include transform(translateY(-50%));
                    @include transition(transform .4s);
                    position: absolute;
                    top: 50%;
                    right: 1em;
                    font-size: 1.2em;
                    color: nth($color, 8);
                }
            }

            .content {
                display: none;
                padding: 0 rem($em-base);
            }
        }
    }
}