.the-content {
  .sidebar {
    position: relative;
    z-index: 1;
    padding: rem(80) rem(40) rem(80) 0;
    font-size: 16px;

    .bg {
      border-left: 1px solid nth($color, 10);
    }

    .content {
      position: relative;
      z-index: 2;
    }

    .boxes {
      .heading {
        @include heading-underline($align: center, $color: nth($color, 1));
        margin-bottom: 40px;
        text-align: center;
        font-size: 13px;
        letter-spacing: 0.25em;

        &:after {
          top: 2em;
          height: 3px;
        }
      }

      .box {
        @include rel-height(65%);
        @include cover;
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        border-top: 3px solid nth($color, 1);

        &:last-child {
          margin-bottom: 0;
        }

        &.linked {
          &:hover {
            .grad {
              background: rgba(#303030, 0.75);
            }
          }
        }

        a {
          position: absolute;
          display: block;
          z-index: 2;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .title {
          position: absolute;
          z-index: 2;
          bottom: 30px;
          left: 30px;
          right: 30px;
          margin: 0;
          padding-left: 20px;
          font-size: 20px;
          border-left: 3px solid nth($color, 1);
          color: #ffffff;
        }

        .grad {
          @include transition(background 0.5s);
          @include linear-gradient(rgba(#000, 0), rgba(#000, 0.5));
        }
      }
    }

    .widget {
      margin-bottom: 40px;

      &#side-nav {
        .root-page {
          padding: 0 rem(16);
          font-size: 18px;
          text-transform: uppercase;
          a {
            color: nth($color, 2);
            text-decoration: none;
            &:hover {
              color: $blue;
              text-decoration: underline;
            }
          }
        }

        .sub-menu {
          display: none;
          margin: 0 1em;

          li {
            a {
              padding: 5px 10px;
              border-color: nth($color, 9);
            }
          }
        }

        @include media($brTablet) {
          .current-menu-item,
          .current-page-ancestor {
            > .sub-menu {
              display: block;
              margin: 0 rem(16);
              li {
                border-color: nth($color, 10);
              }
            }
          }
        }
      }

      &#side-nav,
      &.widget_categories {
        ul {
          li.current-menu-item,
          li.current-page-ancestor,
          li.current-cat {
            > a {
              font-weight: 700;
              color: $green;
            }

            > .sub-menu {
              display: block;
              font-size: rem(14);
            }
          }
          > li {
            a {
            }
          }
        }
      }

      &.widget_search {
        .screen-reader-text {
          display: none;
        }
        form {
          position: relative;
          input {
            display: block;
            &[type="text"] {
              position: relative;
              z-index: 1;
              width: 100%;
              padding-right: 90px;
              background: nth($color, 10);
              border-bottom-color: nth($color, 9);
              &:focus {
                border-bottom-color: nth($color, 1);
              }
            }
            &[type="submit"] {
              position: absolute;
              z-index: 2;
              right: 0;
              bottom: 0;
              padding: 0 10px;
              background-color: transparent;
              color: nth($color, 1);
              &:hover,
              &:active {
                color: nth($color, 7);
              }
            }
          }
        }
      }

      .content {
      }

      header {
        padding: 0 0 20px;

        h2,
        h3 {
          margin: 0 rem(16);
          font-size: 18px;
          font-weight: 700;
          line-height: 1;
          text-transform: uppercase;
          a {
            color: nth($color, 7);
            &:hover {
              color: nth($color, 1);
            }
          }
        }
      }

      p {
        margin: 0;
      }

      ul {
        li {
          position: relative;

          &:first-child {
            > a {
              border-top: none;
            }
          }

          a {
            display: block;
            padding: rem(12) rem(16);
            border-top: 1px solid $blue;
            color: nth($color, 7);
            text-decoration: none;
            &:hover {
              color: $blue;
            }
          }
          &.current_page_item {
            a {
              color: $green;
            }
          }
        }
      }

      .gform_wrapper {
        .gform_footer {
          text-align: center;
        }
      }
    }

    .project-block {
      margin-bottom: 30px;
      h4 {
        @extend h3;
        color: nth($color, 1) !important;
        text-transform: uppercase;
      }

      .manager {
        p {
          margin: 0;
        }

        a {
          color: nth($color, 1);
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }

        .name {
          font-weight: 700;
        }
      }
    }

    #map {
      position: relative;
      z-index: 1;
      iframe {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
      }
    }

    @include media($brDesktop) {
      #contact-box {
      }
      #side-nav {
      }
    }

    @include media($brTablet) {
      padding: 0;
      .bg {
        display: none;
      }

      #side-nav {
        ul {
          li {
            &.current-menu-item {
            }
          }
        }
      }

      .advertisement {
        img {
          margin: 0 auto;
        }
      }
    }
  }
}
