body.landing {
    #the-container {
        margin-top: 0;
    }
    .the-header {
        background-color: transparent;
        .wrap {
            @include outer-container($max-width);
        }

        nav.secondary {
            .menu {
                > li {
                    a {
                        background-color: transparent;
                    }
                }
            }
        }

        @include media($brLargeDesktop) {
            .wrap {
                padding: 0 $margin;
            }
        }

        @include media($brTablet) {
            .menu-toggle {
                right: $margin;
            }
        }
    }
    .section {
        &.s1 {
            .slideshow {
                .slide {
                    height: 600px;

                    .shade {
                        @include linear-gradient(#fff 0%, rgba(#000, 0.1) 30%, rgba(#000, .3) 100%, $fallback: transparent);
                    }

                }
            }

            @include media($brTablet) {
                .slideshow {
                    .slide {
                        height: 380px;
                    }
                }
            }
        }
        &.s3 {
            .cols {
                margin-top: -90px;
            }
        }
    }

    &.scrolled {
        .the-header {
            background: $headerBg;
            nav.secondary {
                height: rem(48);


            }
        }
    }
}