body.home {
    .section {
        &.s1 {
            $height: 460px;
            position: relative;
            z-index: 1;

            .slideshow {
                @include clearfix;

                .slide {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    min-height: 58vh;
                }

                .slide-image {
                    @include cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    .shade {
                        @include linear-gradient(rgba(#fff, 0.25), rgba(#000, 0.5), $fallback: transparent);
                    }
                }
                .btn {
                    border: 1px solid #fff;
                    padding: 0 50px;
                    letter-spacing: .1rem;
                }

                &.fullscreen {
                    .content {
                        @include align-center-v(3);
                        bottom: auto;
                    }
                }

            }

            .content {
                position: relative;
                z-index: 3;
                padding-top: 100px;
                padding-bottom: rem(180);
                padding-left: rem(80);
                padding-right: 14rem;
                color: #ffffff;

                .headline {
                    font-size: 70px;
                    font-weight: 700;
                    font-family: $amatic;
                    color: $blue;
                    max-width: 518px;
                }

                .desc {
                    color: #222;
                    font-size: 24px;
                    + .more {
                        padding-top: 2em;
                    }
                }
                .more {
                    .btn {
                        background: $blue;
                    }
                }
            }

            .anim {
                @include transition(opacity .5s);
                opacity: 0;
                &.ready {
                    opacity: 1;
                }
            }

            .wrap {
                height: 100%;
            }

            @include media($brLargeDesktop) {
                .content {
                }
            }

            @include media($brDesktop) {
                .content {
                    padding-top: 50px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            @include media($brTablet) {
                $height: 360px;
                .slideshow {
                    .slide-image {
                        background-position: center;
                    }
                }

                .content {
                    .headline {
                        font-size: 45px;
                    }
                    .desc {
                        font-size: 18px;
                    }
                }
            }

            @include media($brMobile) {
                .slideshow {
                    .slide-image {
                        background-position: 50% 100%;
                    }
                    &.fullscreen {
                        .content {
                        }
                    }
                }
                .content {
                    .headline {
                        font-size: 40px;
                    }
                    .desc {
                        font-size: 16px;
                        padding-bottom: 50px;
                    }
                    .more {
                        .btn {
                            display: block;
                            width: 100%;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }

        &.s2 {
            position: relative;
            z-index: 2;
            margin-top: rem(-85);
            color: #fff;

            a {
                color: #fff;
            }

            .items {
                @include display(flex);
                .item {
                    @include flex(0 0 33.33%);
                    @include transition(background .4s);
                    position: relative;
                    z-index: 2;
                    padding: rem(30) rem(40);

                    &.i1 {
                        background: $pink;
                        &:hover {
                            background: darken($pink, 10%)
                        }
                    }
                    &.i2 {
                        background: $blue;
                        &:hover {
                            background: darken($blue, 10%)
                        }
                    }
                    &.i3 {
                        background: $green;
                        &:hover {
                            background: darken($green, 10%)
                        }
                    }

                    &:hover {
                        // background: nth($color, 10);
                        // color: nth($color, 7);
                        // a {
                        //     color: nth($color, 7);
                        // }
                    }

                    .text {
                        padding-right: rem(110);
                        .title {
                            font-weight: 700;
                            font-size: 54px;
                            font-family: $amatic;
                            margin-bottom: 10px;
                        }
                        .desc {
                            font-size: 16px;
                            @include media($brMobile) {
                                font-size: 14px;
                            }
                        }
                    }

                    .icon {
                        @include align-center-v(2);
                        right: rem(30);
                        color: #fff;
                        img {
                            height: auto;
                            width: 80px;
                            max-height: 77.5px;
                            object-fit: contain;
                        }
                    }

                }
            }

            @include media($brDesktop) {
                .items {
                    .item {
                        padding: rem(20);
                        .text {
                            padding-right: rem(80);
                            .title {
                                font-size: rem(35);
                            }
                        }
                        .icon {
                            width: rem(60);
                        }
                    }
                }
            }

            @include media($brTablet) {
                .items {
                    display: block;
                }
            }
        }

        &.s3 {
            position: relative;
            z-index: 3;

            h4 {
                margin: 0;
                text-transform: uppercase;
                font-size: rem(18);
            }

            .cols {
                @include clearfix;
                padding: rem(40) rem(150) rem(40) rem(75);
                background: nth($color, 10);
                display: flex;
                .col {
                    height: 100%;
                    &.c1 {
                        @include span-columns(4);
                        padding-right: rem(20);
                        border-right: 2px solid $blue;
                        line-height: 24px;
                        .row {
                            font-size: rem(18);
                            font-weight: 300;
                            margin-bottom: 1em;
                        }

                        .phone {
                            font-weight: 300;
                            a {
                                color: nth($color, 7);
                                &:hover {
                                    color: nth($color, 1);
                                }
                            }
                        }
                        .location {
                            margin-top: 60px;
                            margin-bottom: 60px;
                        }
                        .btn {
                            height: 2.22222rem;
                            line-height: 2.22222rem;
                            background-color: $blue;
                        }
                    }
                    &.c2 {
                        @include span-columns(8);
                        padding-left: rem(50);

                        div.gform_wrapper {
                            li.gfield,
                            li.gfield.gfield_error {
                                padding-top: rem(15);

                                label.gfield_label {
                                    color: $blue;
                                    font-size: 14px;
                                    letter-spacing: .025rem;
                                }

                                div.ginput_container {
                                    #{$all-text-inputs} {
                                        font-size: rem(14);
                                        border: 0;
                                    }
                                    textarea {
                                        height: rem(90);
                                        padding: .5em;
                                    }
                                }

                                &#field_4_3 {
                                    div.ginput_container {
                                        position: relative;
                                        z-index: 2;
                                        &:after {
                                            @include align-center-v(2);
                                            right: .5em;
                                            font-family: "FontAwesome", sans-serif;
                                            font-size: rem(18);
                                            content: "\f073";
                                            color: nth($color, 1);
                                        }
                                    }
                                }
                            }

                            .gform_footer {
                                text-align: right;
                                input[type=submit] {
                                    margin: 0;
                                    background: $blue;
                                }
                            }
                        }
                    }
                }
            }

            @include media($brDesktop) {
                .cols {
                    padding-left: rem(40);
                    padding-right: rem(40);
                    .col {
                        &.c2 {
                            padding-left: rem(20);
                        }
                    }
                }
            }

            @include media($brTablet) {
                .cols {
                    padding: rem(20);
                    .col {
                        &.c1,
                        &.c2 {
                            @include fill-parent;
                            padding: 0;
                        }
                        &.c1 {
                            margin-bottom: rem(20);
                            border-right: 0;
                            border-bottom: none;
                        }
                    }
                }
            }
            @include media($brMobile) {
                .cols {
                    flex-wrap: wrap;
		            flex-direction: column-reverse;
                    .col {
                        &.c1 {
                            border-bottom: none;
                            border-top: 2px solid $blue;
                            margin-top: 1rem;
                            padding-top: 1rem;
                        }
                    }
                }
            }
        }

        &.s4 {
            position: relative;
            z-index: 4;
            padding: rem(50) 0 0;

            .team {
                @include clearfix;
                width: 50%;

                .item {
                    text-align: center;
                    width: 100%;

                    figure {
                        @include cover;
                        height: rem(375);
                    }

                    .name {
                        margin-bottom: 0;
                    }
                    .specialty {
                        margin-bottom: 1em;
                    }
                }
            }

            .text {
                @include align-center-v(2);
                width: 50%;
                right: 0;
                padding-left: rem(40);
            }

            @include media($brDesktop) {
                .team {
                    .item {
                        @include fill-parent;
                        margin-bottom: rem(20);
                    }
                }
            }

            @include media($brTablet) {
                padding-bottom: rem(40);
                .team {
                    width: auto;

                    .item {
                        figure {
                            @include rel-height(70%);
                            background-position: center 0;
                            height: auto;
                        }
                    }

                }

                .text {
                    @include transform(none);
                    position: relative;
                    width: auto;
                    padding-left: 0;
                }
            }
        }

        &.s5 {
            padding: rem(40) 0;
            background: $pink;

            .section-header {
                text-align: center;
                .title {
                    color: #fff;
                }
            }

            .carousel {
                .item {
                    @include transition(opacity .4s);
                    position: relative;
                    z-index: 2;
                    overflow: hidden;
                    opacity: 0;
                    &.ready {
                        opacity: 1;
                    }

                    .pad {
                        margin: rem(30) rem(30) 0;
                        padding: rem(40) rem(30) rem($em-base);
                        border: 1px solid #fff;
                        text-align: center;
                        color: #fff;
                    }

                    .icon {
                        @include align-center-h(2);
                        top: 0;
                        width: rem(60);
                        height: rem(60);
                        border: 1px solid #fff;
                        background: #F4B7CB;
                        border-radius: 50%;
                        i {
                            @include align-center-vh(2);
                            font-size: rem(32);
                            color: #fff;
                        }
                    }

                    blockquote {
                        font-size: rem(18);
                    }
                    .client {
                        font-size: rem(20);
                        font-weight: 700;
                    }
                }
            }

            .items {
                margin-left: rem(-30);
                margin-right: rem(-30);
            }

            @include media($brLargeDesktop) {
                .items {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            @include media($brTablet) {
                .items {
                    .item {
                        .pad {
                            height: auto !important;
                        }
                    }
                }
            }
        }


        &.s6 {
            padding: 60px 0;

            .items {
                @include clearfix;

                .item {
                    @include transition(opacity .4s);
                    @include span-columns(4);
                    position: relative;
                    z-index: 1;
                    opacity: 0;

                    &.ready {
                        opacity: 1;
                    }

                    &:hover {
                        .body {
                            height: 100%;

                            .title {
                                a {
                                    white-space:normal;
                                }
                            }

                            .date {
                                margin-bottom: 1em;
                            }

                            .more {
                                height: rem(40); 
                            }
                        }
                    }

                    figure {
                        position: relative;
                        z-index: 1;
                        height: rem(224);

                        .bg {
                            @include cover;
                        }
                    }

                    .body {
                        @include transition(height .2s);
                        position: absolute;
                        z-index: 2;
                        padding: rem(20);
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: rem(90);
                        background: rgba(#000, .7);
                        text-align: center;

                        .content {
                            @include align-center-v(2);
                            left: rem(20);
                            right: rem(20);
                        }

                        .title {
                            font-size: rem(18);
                            a {
                                display: block;
                                overflow: hidden;
                                white-space: nowrap;
                                -ms-text-overflow: ellipsis;
                                text-overflow: ellipsis;
                                text-decoration: none;
                            }
                        }

                        .date {
                            display: block;
                            font-size: rem(14);
                            font-style: italic;
                        }
                        .more {
                            @include transition(height .2s);
                            overflow: hidden;
                            height: 0;
                        }
                        a, .date {
                            color: #fff;
                        }
                        a .btn {
                            &:hover {
                                background: rgba(255,255,255,0.3);
                                color: #fff;
                            }
                            
                        }
                    }

                }
            }

            .section-header {
                @include clearfix;
                .title {
                    float: left;
                }
                .more {
                    float: right;
                    font-size: rem(18);
                    font-weight: 700;
                    text-transform: uppercase;
                    a {
                        color: $blue;
                    }
                }
            }


            @include media($brDesktop) {

            }

            @include media($brTablet) {
                .items {
                    .item {
                        @include fill-parent;
                        margin: 0 0 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        figure {
                            @include rel-height(60%);
                            height: auto;
                        }
                    }
                }
            }

            @include media($brMobile) {
                .section-header {
                    .title,
                    .more {
                        float: none;
                    }
                }
            }

        }

        .section-header {
            margin: 0 0 rem(30);
            .title {
                margin: 0;
                font-size: rem(54);
                font-weight: 700;
                font-family: $amatic;
                text-transform: uppercase;
            }

            .desc {
                text-align: center;
            }

            @include media($brTablet) {
                margin-bottom: 40px;
                .title {
                    font-size: rem(26);
                }
            }
        }
    }
}