.post-content {
    .testimonials {
        position: relative;
        padding: 2em 0;

        .item {
            padding-bottom: 1em;
            border-bottom: 2px solid nth($color, 10);
        }

        .testimonial {
            font-family: $georgia;
            font-size: em(18);
            font-style: italic;
        }
        .client {
            position: relative;
            text-align: right;

            img {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                border-radius: 50%;
            }

            .name {
                font-weight: 700;
                font-family: $heading-font-family;
            }
        }

        @include media($brMobile) {
        }
    }
}