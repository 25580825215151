#secondary-nav {
    position: absolute;
    z-index: 9;
    top: rem(-30);
    left: 0;
    right: 0;

    > ul {
        @include display(flex);
        background: nth($color, 2);

        > li {
            &.current-menu-item,
            &.current-page-ancestor {
                top: -7px;
                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    right: 0;
                    height: 7px;
                    background: nth($color, 10);
                }
                > a {
                    background: nth($color, 3);
                }
            }
            > a {
                height: 100%;
                padding: rem(16) rem(30);
                font-size: rem(18);
            }
        }
        li {
            position: relative;
            z-index: 10;
            a {
                display: block;
                color: $mainBg;
                &:hover {
                    background: nth($color, 1);
                }
            }
        }
        .sub-menu {
            display: none;
            position: absolute;
            z-index: 9;
            background: nth($color, 2);
            li.current-menu-item {
                > a {
                    background: nth($color, 3);
                }
            }
            a {
                padding: rem(8) rem(30);

            }
        }
    }

    @include media($brLargeDesktop) {
        > ul {
            > li {
                > a {
                    font-size: rem(16);
                }
            }
        }
    }

    @include media($brDesktop) {
        > ul {
            > li {
                > a {
                    font-size: rem(14);
                }
            }
        }
    }

    @include media($brTablet) {
        display: none;
    }
}