#mobile-nav {
  @mixin setup($width: 100%) {
    width: $width;
    &.off {
      @include transform(translate($width, 0));
    }
  }

  $width: 20%;
  $padding: 30px;
  $subpadding: 30px;

  @include setup($width);
  @include transition(transform 0.3s, top 0.3s);
  position: fixed;
  right: 0;
  z-index: 10000;
  bottom: 0;
  background: #fff;
  overflow-y: auto;

  #main-menu-mobile {
    display: none;
  }

  &.off {
    visibility: hidden;
  }
  &.on {
    @include transform(translate(0, 0));
    visibility: visible;
    + #the-container {
      //@include transform(translate(-$width, 0));
      //@include transition(transform .3s);
    }
    .menu {
      padding-top: 20px;
      margin-bottom: 20px;
      li {
        position: relative;
        padding: 0;

        &.open {
          > a {
            color: nth($color, 1);
            > .sub-toggle {
              .on {
                display: inline-block;
              }
              .off {
                display: none;
              }
            }
          }

          a {
            border-color: nth($color, 2);
          }
        }

        &.menu-item-has-children,
        &.page_item_has_children {
          position: relative;
          > a {
            > .sub-toggle {
              position: absolute;
              z-index: 3;
              top: 0;
              right: 0;
              display: block;
            }
          }
        }
        &.current-menu-item {
        }

        &.current_page_item,
        &.current-menu-item,
        &.current-menu-ancestor,
        &.current-page-ancestor {
          > a {
            color: $pink;
          }

          > .sub-menu {
          }
        }

        a {
          display: block;
          color: nth($color, 7);
        }

        .sub-toggle {
          display: none;
          width: 45px;
          height: 45px;
          text-align: center;
          line-height: 45px;
          .on {
            display: none;
          }
        }
      }

      > ul > li,
      > li {
        > a {
          padding: 10px $padding;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 700;
        }

        > .sub-menu,
        > .children {
          background: $blue;
          li {
            padding: 0;
          }
          a {
            padding: 10px $subpadding;
            text-decoration: none;
            color: #fff;
          }
        }
      }

      .sub-menu,
      .children {
        display: none;
      }
    }
  }

  .more {
    padding: 0 $padding;

    .social-networks {
      a {
        margin: 0 10px 0 0;
        i {
          color: nth($color, 8);
        }
      }
    }

    .menu {
      background: none;
      li {
        margin-bottom: 5px;
        i {
          margin-right: 10px;
        }
        a {
          color: nth($color, 8);
          border: 1px solid nth($color, 8);
          border-radius: 22px;
        }
      }
    }
  }

  .menu-trigger {
    margin-bottom: 20px;
    padding: 0 $padding;
    display: block;
    text-align: right;
    text-decoration: none;
    i {
      color: #fff;
      font-size: 32px;
    }
  }

  .carousel {
    a {
      @include clearfix;
    }

    .pad {
      display: block;
      padding: 20px 60px;
    }

    figure {
      @include rel-height(68.2%);
      margin: 0 auto 10px;
      background: no-repeat center;
      -webkit-background-size: cover;
      background-size: cover;

      + .label {
        display: inline-block;
        clear: left;
      }
    }

    .owl-controls {
      .owl-buttons {
        .owl-prev,
        .owl-next {
          @include align-center-v(2);
          color: #fff;
          font-size: 36px;
        }

        .owl-prev {
          left: 10px;
        }

        .owl-next {
          right: 10px;
        }
      }
    }
  }

  @include media($brTablet) {
    @include setup(100%);
    #main-menu-mobile {
      display: block;
    }
    #additional-menu {
      display: none;
    }
  }
}

#mobile-nav .secondary ul.menu > li {
  &:nth-child(2) {
    a {
      background-color: $pink !important;
    }
  }
  &:nth-child(3) {
    a {
      background-color: $blue !important;
    }
  }
}
