.post-content {
    ul.gallery {
        @extend .unstyled;
        @include clearfix;
        padding: 2em 0;
        li {
            @include span-columns(3);
            @include omega(4n);
            margin-bottom: 1em;
            a {
                img {
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    border: 1px solid #e0e0e0;
                }

                &:hover {
                    img {
                        padding: 2px;
                        border: 3px solid #d0d0d0;
                    }
                }
            }
        }

        @include media($brTablet) {
            li {
                @include span-columns(4 of 12);
                @include omega-reset(4n);
                @include omega(3n);
            }
        }

        @include media($brMobile) {
            li {
                @include span-columns(6 of 12);
                @include omega-reset(3n);
                @include omega(2n);
            }
        }

    }
}