@import "accordion";
@import "flexible";
@import "gallery";
@import "tabs";
@import "team";
@import "testimonials";

@import "template/contact";
@import "template/home";
@import "template/landing";

.the-content {
  .main.has-navigation {
    background: nth($color, 10);
  }

  @include media($brTablet) {
    .main.has-navigation {
      background-color: transparent;
    }
  }
}

.post-content {
  @include clearfix;
  font-weight: 300;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $blue;
    font-weight: bold;
  }
  h2 {
    margin-bottom: 0.25em;
  }

  // LISTS
  ol,
  ul {
    padding: 1em 0;
    list-style: none;
    &.unstyled {
      padding-left: 0;
      margin: 0;
      list-style-type: none;
    }
    li {
      margin: 0;
      padding: 0.25em 0;
    }
  }
  ol {
    margin-left: 2em;
    li {
      list-style: decimal;
    }
  }
  ul {
    li {
      position: relative;
      z-index: 1;
      padding-left: 25px;

      &:before {
        position: absolute;
        top: 0.75em;
        left: 0;
        z-index: 2;
        display: block;
        content: "";
        width: 6px;
        height: 6px;
        background: nth($color, 1);
      }
    }
  }

  ul.unstyled,
  ul.accordion,
  ul.tabs,
  ul.gallery,
  ul.tribe-events-sub-nav,
  ul.tribe-bar-views-list,
  .pagination ul {
    li {
      padding: 0;
      &:before {
        display: none;
        content: "";
      }
    }
  }

  ul.sitemap.unstyled {
    > li {
      display: block;
      padding: 0.5em 0;
      > a {
        font-size: rem(20);
        font-weight: 700;
        font-family: $heading-font-family;
      }

      ul {
        padding: 0 1em;
        li {
          @include bullet-list(
            $icon: "\f105",
            $top: 2px,
            $bullet-color: nth($color, 8)
          );
          &:before {
            background: none;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }

  // PARAGRAPH
  p {
    margin: 0 0 1em;
    + ul {
      padding-top: 0;
    }
  }

  // IMAGE
  img {
    display: block;
    max-width: 100%;
    height: auto;

    &[class*="wp-image"],
    &.wp-post-image {
      padding: 5px;
      background: #ffffff;
    }
  }

  // TABLE
  table {
    width: 100%;
    margin: 1em 0;
  }

  // OTHER STUFF
  blockquote {
    color: lighten($base-font-color, 15);
    margin: $base-spacing 0;
    padding-left: $base-spacing / 2;
  }
  small {
    font-size: 0.8em;
  }

  iframe {
    display: block;
    width: 100%;
  }

  @include media($brTablet) {
    iframe {
      display: block;
      width: 100%;
    }
  }

  /* WORDPRESS CORE
================================================================================================ */
  .alignnone {
    float: none;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 2em;
  }

  .alignleft {
    display: inline;
    float: left;
    margin-right: 2em;
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .txt-alignleft {
    text-align: left;
  }

  .txt-aligncenter {
    text-align: center;
  }

  .txt-alignright {
    text-align: right;
  }

  .wp-caption {
    width: auto !important;
    margin-bottom: 20px;
    border-bottom: 3px solid #eaeaea;

    img {
      display: block;
      border: 0 none;
      height: auto;
      margin: 0;
      padding: 0;
      max-width: 100%;
      width: auto;
    }
    p.wp-caption-text {
      margin: 0;
      padding: 20px 0;
      font-size: em(28px);
      font-weight: 700;
    }
  }

  &.not-found {
    .cols {
      @include clearfix;
      .col {
        @include span-columns(6);
      }
    }
    h1 {
      font-size: 100px;
      margin-bottom: 1rem;
      @include media($brTablet) {
        font-size: 80px;
      }
      @include media($brMobile) {
        font-size: 45px;
      }
    }
    input[type="submit"] {
      background: $blue;
    }
    input[type="text"] {
      &:active,
      &:focus {
        border-bottom-color: $blue;
      }
    }

    h3 {
      margin-bottom: 0;
    }

    @include media($brTablet) {
      .cols {
        .col {
          @include fill-parent;
        }
      }
    }
  }
}

/* CONTENT
================================================================================================ */

.post {
  @include clearfix;
  margin-bottom: 2em;
}

.post-header {
  margin-bottom: 10px;
  .post-title {
    margin: 0;
    padding: 0;
    font-size: em(25px);
    font-weight: 700;
  }
  .post-meta {
    padding: 5px 0;
    font-size: 75%;
  }
  time {
    font-weight: 700;
    font-size: em(14px);
    text-transform: uppercase;
    color: nth($color, 8);
  }
}

.post-footer {
  .posts-links {
    @include clearfix;
    .prev {
      float: left;
    }
    .next {
      float: right;
    }
    a {
      color: nth($color, 7);
      &:hover {
        color: nth($color, 1);
      }
    }
  }
}

.post-thumbnail {
  float: left;
  margin-right: 2em;
  margin-bottom: 1em;
  border: 5px solid #fff;

  img {
    display: block;
  }

  @include media($brMobile) {
    float: none;
    display: inline-block;
    margin-right: 0;
  }
}

.post-excerpt {
  overflow: hidden;
  p {
    margin-top: 0;
  }
}

.page-header {
  padding: 0 0 2em;
  .page-title {
    margin: 0;
    padding-bottom: 10px;
    font-size: em(30px);
    font-weight: 900;
    color: nth($color, 2);
    text-transform: uppercase;
    border-bottom: 3px solid nth($color, 9);
  }

  @include media($brTablet) {
    .page-title {
    }
  }
}

.section-footer {
}

.blog,
.archive,
.search {
  article {
    @include clearfix;
    margin-bottom: 2em;
  }
  .post-header {
  }
  .post-meta {
    clear: left;
  }
}

.single {
  .post-meta {
    padding-top: 1em;
  }
}
