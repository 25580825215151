.the-content.contact {
  .main {
    @include media($brTablet) {
      padding: 2rem 1rem;
    }
  }
  .post-content {
    .cols {
      @include clearfix;
      .col {
        @include span-columns(6);
      }
    }

    .location {
      margin-bottom: 20px;
      .company-name {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 30px;
      }
    }
    .map {
      .overlay {
        @extend .shade;
        z-index: 3;
        &.disabled {
          display: none;
        }
      }
    }
    .gform_body {
      input,
      textarea {
        &:focus {
          border-bottom-color: $blue;
        }
      }
    }
    .gform_footer {
      input[type="submit"] {
        background-color: $blue;
      }
    }
  }

  @include media($brTablet) {
    .post-content {
      .cols {
        .col {
          @include fill-parent;
          &.c1 {
            margin-bottom: 2em;
          }
        }
      }

      .location {
        .company-name {
          font-size: 24px;
        }
      }
    }
  }
}
