.section.cta {
  padding: 50px 0;
  background: $green;
  color: #fff;
  .text {
    margin-right: rem(140);
    .title {
      margin: 0;
      font-family: $amatic;
      font-weight: 700;
      font-size: rem(54);
      text-transform: uppercase;
    }
  }

  .more {
    @include align-center-v(2);
    right: 0;
    .btn {
      text-transform: uppercase;
      border-color: #fff;
      background: transparent;
      color: #fff;
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  @include media($brTablet) {
    .text {
      margin-right: 0;
      margin-bottom: 1em;
      text-align: center;
    }

    .more {
      @include transform(none);
      position: relative;
      top: auto;
      text-align: center;
    }
  }
}
