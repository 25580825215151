$max-width: 1280px;
$margin: 40px; // base margin for the screen edges on screen sizes lower than $max-width

$brMobile: new-breakpoint(max-width 480px 4);
$brTablet: new-breakpoint(max-width 780px 6);
$brDesktop: new-breakpoint(max-width 1080px 12);
$brLargeDesktop: new-breakpoint(max-width 1380px 12);

$imgDir: "../images";

$color: (
  #014488,
  // 1 main color, usually the main color of the logo, used for default button bg, links, active menu items
    #080653,
  // 2 usually default button hover color
    #5197d5,
  // 3 .alt.button bg
    #ede2fa,
  // 4 .alt.button:hover bg
    #a3f18c,
  // 5 .strong.button bg
    #88bb00,
  // 6 .strong.button:hover bg
    #414042,
  // 7 .post-content color
    #707070,
  // 8 borders, footer font color
    #ddd,
  // 9 borders
    #ebebeb // 10 borders, light section bgs etc
);

$mainBg: #fff;
$headerBg: $mainBg;
$footerBg: nth($color, 2);
$blue: #5098d6;
$pink: #ff769d;
$green: #3fc466;

/* BASE SETTINGS
================================================================================================ */
$base-font-family: "Roboto", sans-serif;
$amatic: "Amatic SC", cursive;
$heading-font-family: $base-font-family;
$base-font-color: nth($color, 7);
$em-base: 18px;

// Line height
$base-line-height: 1.25;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

$fa-font-path: "../css/fonts";
$ionicons-font-path: "../css/fonts";

$buttons-list: "button", 'input[type="button"]', 'input[type="reset"]',
  'input[type="submit"]', "a.button", "button.button", "button.button.alt",
  "button.button.alt.disabled", "a.button", "a.button.alt", "a.button.disabled",
  "a.button.alt.disabled";

$all-buttons: assign-inputs($buttons-list);
$all-buttons-active: assign-inputs($buttons-list, active);
$all-buttons-focus: assign-inputs($buttons-list, focus);
$all-buttons-hover: assign-inputs($buttons-list, hover);
