@charset "UTF-8";

@mixin breakpoint($query:$feature $value $columns, $total-columns: $grid-columns) {
  @include -neat-warn("The breakpoint() mixin was renamed to media() in Neat 1.0. Please update your project with the new syntax before the next version bump.");

  @if length($query) == 1 {
    @media screen and ($default-feature: nth($query, 1)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: $total-columns;
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else if length($query) == 2 {
    @media screen and (nth($query, 1): nth($query, 2)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: $total-columns;
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else if length($query) == 3 {
    @media screen and (nth($query, 1): nth($query, 2)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: nth($query, 3);
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else if length($query) == 4 {
    @media screen and (nth($query, 1): nth($query, 2)) and (nth($query, 3): nth($query, 4)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: $total-columns;
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else if length($query) == 5 {
    @media screen and (nth($query, 1): nth($query, 2)) and (nth($query, 3): nth($query, 4)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: nth($query, 5);
      @content;
      $grid-columns: $default-grid-columns;
    }
  } @else {
    @include -neat-warn("Wrong number of arguments for breakpoint(). Read the documentation for more details.");
  }
}

@mixin nth-omega($nth, $display: block, $direction: default) {
  @include -neat-warn("The nth-omega() mixin is deprecated. Please use omega() instead.");
  @include omega($nth $display, $direction);
}

/// Resets the active display property to `block`. Particularly useful when changing the display property in a single row.
///
/// @example scss - Usage
///   .element {
///     @include row(table);
///     // Context changed to table display
///   }
///
///   @include reset-display;
///   // Context is reset to block display

@mixin reset-display {
  $container-display-table: false !global;
  @include -neat-warn("Resetting $display will be deprecated in future versions in favor of the display(){...} mixin.");
}

/// Resets the active layout direction to the default value set in `$default-layout-direction`. Particularly useful when changing the layout direction in a single row.
///
/// @example scss - Usage
///   .element {
///     @include row($direction: RTL);
///     // Context changed to right-to-left
///   }
///
///   @include reset-layout-direction;
///   // Context is reset to left-to-right

@mixin reset-layout-direction {
  $layout-direction: $default-layout-direction !global;
  @include -neat-warn("Resetting $direction will be deprecated in future versions in favor of the direction(){...} mixin.");
}

/// Resets both the active layout direction and the active display property.
///
/// @example scss - Usage
///   .element {
///     @include row(table, RTL);
///     // Context changed to table table and right-to-left
///   }
///
///   @include reset-all;
///   // Context is reset to block display and left-to-right

@mixin reset-all {
  @include reset-display;
  @include reset-layout-direction;
}
